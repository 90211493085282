import React from 'react';
import {navigate} from 'gatsby-link';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import imgAboutBanner from '../../images/about-banner.jpeg';
import imgAboutRightAvatar from '../../images/about-right-avatar.jpg';
import imgAboutLeftAvatar from '../../images/about-left-avatar.png';
import imgAboutAvatar3 from '../../images/about-avatar-3.jpeg';
import imgAboutImg4 from '../../images/about-img-4.png';
import imgAboutSection from '../../images/about-section-1.png';
import imgAbout202502 from '../../images/about-2025-02.jpg';
import imgAbout202503 from '../../images/about-2025-03.jpg';
import imgAbout202504 from '../../images/about-2025-04.jpg';
import imgAbout202505 from '../../images/about-2025-05.jpg';
import imgAboutGrid1 from '../../images/about-grid-1.png';
import imgAboutGrid2 from '../../images/about-grid-2.png';
import imgAboutGrid3 from '../../images/about-grid-3.png';
import imgAboutGrid4 from '../../images/about-grid-4.png';
import imgLogo from '../../images/logo.png';
import FixedRatioImage from '../../components/FixedRatioImage';
import {BREAK_POINTS} from '../../domain/Constants';
import ContactForm from '../../components/ContactForm';

function AboutPage(props) {
  const app = React.useContext(AppContext.Context);

  return (
    <Wrapper>
      <div className="banner">
        <FixedRatioImage
          mode={'cover'}
          width={'100%'}
          image={imgAboutBanner}
          ratio={550 / 1440}
        />
      </div>
      <div className="section-1">
        <h1>Le Ruban Pâtisserie</h1>
        <p>
          法朋重視的是能夠一同分享的溫暖感受，
          <br />
          結合生活土地上的元素，使用當季新鮮的材料，
          <br />
          於傳統中解構更新，呈現鮮明的季節時令感，
          <br />
          創造一個能夠滿足現代人們對於甜品的需求與想像的甜點城堡。
        </p>
      </div>
      <div className="container">
        <div className="about-content">
          <div className="info">
            <h4 className="title">{`「一個社區裡，\n只要有一家甜點店，\n那個社區就會幸福。」`}</h4>
            <p>{`我想要呈現給客人的甜點，是每個季節、每個時令，結合自己生活土地上的食材所做成的甜點。每年都會做產地拜訪，四處尋找由台灣農民所栽種的哈密瓜、芒果、無花果、草莓、地瓜等， 雖然這些高品質農作物產量少，多少會反應在成本上，但犧牲一點利潤沒關係，重點是找到台灣本土、安全又品質好的食材。\n甜點是生活的一部份，我希望能夠落實這個理想。開店前我在日本學習，看到令人感動的甜點生活感，「小朋友放學後跟著媽媽來店裡，說『我想買這個回家，跟爸爸一起吃。』爺爺與奶奶輕聲討論著今天想要帶什麼甜點回去呢? 甚至男性上班族拎著公事包，到店裏外帶甜點。」法朋的蛋糕不僅是給一個人吃，還希望帶給整個家庭快樂。`}</p>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${imgAboutRightAvatar}")`,
            }}></div>
        </div>
      </div>
      <div className="full-width-section">
        <FixedRatioImage
          mode={'cover'}
          width={'100%'}
          image={imgAboutSection}
          ratio={450 / 1440}
        />
      </div>
      <div className="container">
        <div className="about-content reverse" style={{marginTop: 70}}>
          <div className="info">
            <h4 className="title">{`「回歸原點，\n讓甜點成為主角。」`}</h4>
            <p>{`以前甜點都是咖啡店中飲品的配角，我們沒有特地去甜點店吃蛋糕這樣的習慣與環境，因此非常想成立以「甜點」為主角的店。\n我以「日系法式甜點」定義法朋的甜點創作。法式甜點的精緻浪漫，與日式甜點的嚴謹扎實，再加上台灣處於亞熱帶地區，氣候高溫潮濕，法式甜點的厚重需要調整成質地輕盈卻又不失風味，才會讓人產生舒適感。\n這樣的呈現落實在每天每位師傅的基本功，重視甜點製作的手法與對食材品質的堅持要求，讓幼稚園的小朋友到爺爺奶奶，都能夠安心食用。`}</p>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${imgAboutLeftAvatar}")`,
            }}></div>
        </div>

        <div className="about-content" style={{marginTop: 70}}>
          <div className="info">
            <h4 className="title">{`「我們依循季節，\n融入當季新鮮的食材，\n創作出最美好的甜點！」`}</h4>
            <p>{`法朋從不妥協食材的選用，甜點要美味，優質食材是核心的關鍵因素。從開店之初，就使用日本九州的熊本麵粉、北海道或九州乳源的純生完全無添加物的鮮奶油、屏東大武山農產AA級雞蛋、法國產區認證的發酵奶油等，做出各種撫慰人心的甜點。\n季節性是我們很重要的元素，實際走訪產地後，對台灣的果物農產品了解越多，就越著迷深耕於使用台灣季節時令產物。春、夏、秋、冬四季更迭，以苗栗無毒草莓、雲林哈密瓜、屏東檸檬、台南愛文芒果等季節性在地農產品，將「台灣寶島」這個概念，結合在產品設計中，更希望這些有台灣元素的甜點，能讓到台灣的外國旅人，輕鬆地以甜點認識我們的家鄉。`}</p>
          </div>
          <div
            className="image bigger"
            style={{
              backgroundImage: `url("${imgAboutAvatar3}")`,
            }}></div>
        </div>
      </div>

      <div className="full-width-section">
        <FixedRatioImage
          mode={'cover'}
          width={'100%'}
          image={imgAbout202502}
          ratio={450 / 1440}
        />
      </div>
      <div className="container">
        <div className="about-content" style={{marginTop: 70}}>
          <div className="info">
            <h4 className="title">{`「2017~2021年\n與Encore 15 kilos合作\n兒童節公益甜點活動！」`}</h4>
            <p>{`法朋與Encore 15 kilos法日美食翻譯家團隊合作舉辦「兒童節公益甜點」活動。活動期間，顧客在法朋門市捐款100元至公益捐款箱，即可享用限量的「兒童節公益甜點」。`}</p>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${imgAbout202503}")`,
            }}></div>
        </div>

        <div className="about-content reverse" style={{marginTop: 70}}>
          <div className="info">
            <h4 className="title">{`「參與于美人公益活動\n2019年一片甘心做好事\n2020年和你一起做好事」`}</h4>
            <p>{`2019年，法朋參與于美人公益活動計畫「一片甘心做好事」，販售的鐵盒餅乾每盒捐出100元，捐贈給專門收容未婚媽媽及嬰兒的「財團法人國際單親兒童文教基金會附設台南市私立麻二甲之家」。為此活動，法朋特別創作了甜鹹兩種口味的餅乾禮盒，包括「森林甜蜜小酥餅禮盒」與「海洋鹽味小酥餅禮盒」，共八款風味各異的餅乾。這些餅乾融入各地風味，帶來極致美味。感謝大家的支持，活動期間共捐出新臺幣174萬元，並創下兩週內銷售16,000盒餅乾的紀錄。\n2020年，法朋再次參與于美人公益計畫「2020和你一起做好事」，每售出一盒禮盒餅乾，即捐出100元給「婦女救援社會福利事業基金會」。此次推出的「帶著愛旅行」禮盒包含檸檬旅人蛋糕和六款精緻法式餅乾。感謝顧客支持，活動共捐出新台幣84萬4千元支票予婦女救援基金會。`}</p>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${imgAbout202504}")`,
            }}></div>
        </div>

        <div className="about-content" style={{marginTop: 70}}>
          <div className="info">
            <h4 className="title">{`「2024年法朋參與\n小米保種公益計畫推出點心禮盒\n讓小米在台灣這片土地上生生不息」`}</h4>
            <p>{`2024年，法朋×綠色餐飲指南×慈心有機農業發展基金會義賣專案。法朋主廚李依錫師傅應綠色餐飲指南之邀，加入小米保種公益計畫，進到金崙賓茂國中，運用部落農友的有機友善小米教作特色烘焙品，開發出期間限定限量的小米司康點心禮盒，有香椿、刺蔥烏龍茶、洛神花共3款，販售收入扣除必要成本後，全數捐入慈心基金會的小米專案，讓小米品系繼續在台灣這片土地上生生不息，也讓小米這個民族味蕾代代傳承。`}</p>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${imgAbout202505}")`,
            }}></div>
        </div>
      </div>

      <div className="full-width-section" style={{backgroundColor: '#fff'}}>
        <div className="content">
          <div className="info">
            <h4>{'Le Ruban Pâtisserie   法朋烘焙甜點坊'}</h4>
            <h3>{`「甜點不該是奢侈品\n而是生活用品，是生活的一部份。」`}</h3>
            <p>{`法朋是一間位於住宅區的法式甜點店，除了服務社區內固定的老顧客以外，也提供喜餅、彌月、外燴、宅配等服務，希望您值得紀念的場合都可以讓法朋一起參與，並能讓這些珍貴的生活片段更加美好。\n主廚李依錫希望大家能夠將分享甜點作為生活的一部分。\n室內與室外皆以俐落的白色調為主，入口用大面積的透明玻璃，使門面更加通透。內部空間則以乾淨清新的色調襯托出甜品的多樣性與豐富，使整體室內氛圍更加溫潤精緻。\n甜點以冷藏慕斯櫃、手工巧克力櫃、常溫蛋糕區、及餅乾燒果子區作為分類，完整呈現甜點的各種樣態，讓造訪法朋的朋友能夠輕鬆地挑選到自己喜歡的甜點。`}</p>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${imgAboutImg4}")`,
            }}></div>
        </div>
      </div>

      <div className="grid-container" style={{marginBottom: 60}}>
        <div className="row" style={{marginBottom: 30}}>
          <FixedRatioImage
            className="cell"
            mode={'cover'}
            width={'100%'}
            image={imgAboutGrid1}
            ratio={667 / 1000}
          />
        </div>
        <div
          className="row"
          style={{marginBottom: 30, justifyContent: 'space-between'}}>
          <div className={'cell'} style={{width: 'calc((100% - 40px)/3)'}}>
            <FixedRatioImage
              className="cell"
              mode={'cover'}
              width={'100%'}
              image={imgAboutGrid2}
              ratio={478 / 320}
            />
          </div>
          <div className={'cell'} style={{width: 'calc((100% - 40px)/3)'}}>
            <FixedRatioImage
              className="cell"
              mode={'cover'}
              width={'100%'}
              image={imgAboutGrid3}
              ratio={478 / 320}
            />
          </div>
          <div className={'cell'} style={{width: 'calc((100% - 40px)/3)'}}>
            <FixedRatioImage
              className="cell"
              mode={'cover'}
              width={'100%'}
              image={imgAboutGrid4}
              ratio={478 / 320}
            />
          </div>
        </div>
      </div>

      {/* <div className="bussiness-section">
        <div className="content">
          <img src={imgLogo} width={200} height={88} />
          <div className="info">
            <h4>Le Ruban Pâtisserie 法朋烘焙甜點坊</h4>
            <p>營業時間 週一至週日 11:30-19:00</p>
            <p>聯絡電話 02 8978 8332</p>
            <p>門市地址 10693台北市大安區仁愛路四段300巷20弄11號</p>
          </div>
        </div>
      </div>
      <div className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14459.729915922895!2d121.5537954!3d25.0363654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfeba6a0310cbc375!2zTGUgUnViYW4gUMOidGlzc2VyaWUg5rOV5pyL54OY54SZ55Sc6bue5Z2K!5e0!3m2!1szh-TW!2stw!4v1626082284587!5m2!1szh-TW!2stw"
          width="100%"
          height="350"
          style={{border: 0}}
          loading="lazy"></iframe>
      </div>
      <div className="contact-section">
        <div className="content">
          <ContactForm />
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .banner {
    width: 100%;
  }
  & .section-1 {
    padding: 70px var(--content-padding);
    max-width: var(--content-max-width);
    margin: 0 auto;

    & > h1 {
      font-size: 24px;
      color: #595757;
      letter-spacing: 0;
      margin-bottom: 25px;
      text-align: center;
    }

    & > p {
      font-size: 14px;
      color: #595757;
      letter-spacing: 2px;
      text-align: center;
      line-height: 30px;
      text-align: center;
    }
  }

  & .container {
    padding: 0px var(--content-padding);
    max-width: var(--content-max-width);
    margin: 0 auto;
    & .about-content.reverse {
      flex-direction: row-reverse;
    }
    & .about-content {
      margin-bottom: 70px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      background-color: #ffffff;

      & > .info {
        flex: 1;
        padding: 40px 50px 70px;

        & > .title {
          font-size: 30px;
          color: #595757;
          letter-spacing: 1.76px;
          text-align: left;
          line-height: 60px;
          white-space: pre-wrap;
        }

        & > p {
          margin: 40px 0px;
          font-size: 14px;
          color: #595757;
          letter-spacing: 0;
          text-align: justify;
          line-height: 30px;
          white-space: pre-line;
        }
      }

      & > .image {
        flex-basis: 420px;
        flex-shrink: 0;
        background-size: cover;
        background-position: center;
      }
      & > .image.bigger {
        flex-basis: 580px;
      }
    }
  }

  & .full-width-section {
    & .content {
      padding: 60px var(--content-padding);
      max-width: var(--content-max-width);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      & > .info {
        margin-right: 30px;
        flex-grow: 2;
        flex-basis: 600px;
        & > h4 {
          font-size: 24px;
          color: #595757;
          letter-spacing: 1.41px;
          margin-bottom: 48px;
          white-space: pre-line;
        }

        & > h3 {
          font-size: 24px;
          color: #595757;
          letter-spacing: 1.41px;
          line-height: 50px;
          margin-bottom: 56px;
          white-space: pre-line;
        }

        & > p {
          font-size: 14px;
          color: #595757;
          letter-spacing: 2px;
          line-height: 30px;
          white-space: pre-line;
        }
      }

      & > .image {
        flex-grow: 1;
        flex-basis: 320px;
        background-position: center;
        background-size: cover;
      }
    }
  }

  & .grid-container {
    max-width: var(--content-max-width);
    margin: 0 auto;
    margin-top: 70px;
    & .row {
      display: flex;

      & > .cell {
        background-position: center;
        background-size: cover;
      }
    }
  }

  & .bussiness-section {
    background-color: #fff;
    & .content {
      padding: 80px var(--content-padding);
      max-width: var(--content-max-width);
      margin: 0 auto;
      display: flex;
      align-items: center;

      & > .info {
        margin-left: 150px;
        & > h4 {
          font-size: 24px;
          color: #595757;
          letter-spacing: 0;
          margin-bottom: 20px;
        }

        & > p {
          font-size: 14px;
          color: #595757;
          letter-spacing: 2px;
          line-height: 30px;
        }
      }
    }
  }

  & .contact-section {
    & .content {
      padding: 50px var(--content-padding) 70px;
      max-width: var(--content-max-width);
      margin: 0 auto;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & .container {
      & .about-content,
      & .about-content.reverse {
        flex-direction: column-reverse;
      }
    }

    & .full-width-section {
      & .content {
        flex-direction: column-reverse;

        & .info {
          flex-basis: auto;
          margin-right: 0px;
          & > h4 {
            margin-top: 40px;
            margin-bottom: 20px;
          }
          & > h3 {
            margin-bottom: 20px;
          }
        }
      }
    }

    & .bussiness-section {
      & .content {
        flex-direction: column;
        & > .info {
          margin-left: 0px;
          margin-top: 30px;
        }
      }
    }
  }
`;

export default AboutPage;
